import './SearchBooking.css'

import { Button, Col, Form, Input, Row } from 'antd'
import { useSearchBooking } from 'hooks/gts/useSearchBooking'
import React from 'react'
import { EMAIL_PATTERN } from 'utils/patterns'

import LogoGTSRedondo from '../../assets/LogoGTSRedondo.svg'

const SearchBooking = (): React.ReactElement => {
  const {
    onPressConfirm
  } = useSearchBooking()

  return (
    <div className="search-booking">
      <Row align="middle" justify="center">
        <Col span={24}>
          <img src={LogoGTSRedondo} alt="Logo GTS" style={{ maxHeight: '512px', height: '40vh', width: '40vw', marginBottom: '10px' }} />
        </Col>
        <Col xs={16} md={12} xl={8}>
          <Form
            initialValues={{ remember: true }}
            onFinish={onPressConfirm}
          >
            <Form.Item
              name="bookingId"
              rules={[
                { required: true, message: 'Insira o booking Id!' }
              ]}
            >
              <Input placeholder="Booking ID" size="large" maxLength={7} />
            </Form.Item>
            <Form.Item
              name="clientEmail"
              rules={[
                { 
                  required: true, 
                  message: 'E-mail inválido', 
                  pattern: EMAIL_PATTERN
                }
              ]}
            >
              <Input placeholder="E-mail do cliente cadastrado no GTS" size="large" />
            </Form.Item>
            <Form.Item
              name="clientId"
              rules={[
                { required: true, message: 'Insira o id!' }
              ]}
            >
              <Input placeholder="ID do Cliente" size="large" />
            </Form.Item>
            <Form.Item>
              <Button id="booking-search-submit-button" htmlType="submit" type="primary">
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default SearchBooking
