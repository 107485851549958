import { Alert } from 'antd'

import { type FetchDataProps } from './types'

const FetchData = <TData extends unknown>({
  hasData,
  isLoading,
  children,
  error,
}: FetchDataProps<TData>) => {

  if(!!error && !isLoading) return <Alert type='error' message={`Ocorreu um erro ao carregar a lista do liveness. Erro: ${error.message}`}  />

  if(!error && !!isLoading && !hasData) return <p>Carregando...</p>

  return <>{children}</>
}

export default FetchData