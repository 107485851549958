import { useNavigate } from 'react-router-dom'
import { verifyCredentialsService } from 'services/GtsService'
import { FormGTSCredentialsProps } from 'services/GtsService/types'

export const useSearchBooking = () => {
  const navigate = useNavigate()

  const onPressConfirm = (data: FormGTSCredentialsProps) => 
    verifyCredentialsService(
      data, 
      () => navigate(data.bookingId)
    )
    
  return {
    onPressConfirm,
  }
}