import useSWR, { useSWRConfig } from 'swr'

import { SwrHookAdapterProps } from './types'

export const useSwrAdapter = <TFilters, TResponse>({
  filters,
  promiseApi,
  defaultValue,
  options
}: SwrHookAdapterProps<TFilters, TResponse>) => 
{
  const key = JSON.stringify(filters)

  const { mutate } = useSWRConfig()
  
  const {
    data,
    ...hookAdapter
  } = useSWR(
    key,
    async () => 
      await promiseApi(filters)
        .then(res => res)
        .catch(err => {
          throw new Error(err)
        }),
    {
      ...options
    }
  )

  const revalidate = async () => await mutate(key)

  return {
    data: data ? data.data : defaultValue as TResponse,
    revalidate,
    ...hookAdapter
  }

}