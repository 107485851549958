import { Button, Collapse, Tag } from 'antd'
import image from 'assets/liveness-instructions.jpeg'
import { FetchData } from 'components'
import { StatusCard } from 'components/atomic/atoms'
import { StatusCardType } from 'components/atomic/atoms/StatusCard/enum'
import modalController from 'components/Modal'
import { useLivenessList } from 'hooks/liveness/useLivenessList'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bindActionCreators, type Dispatch } from 'redux'
import { LIVENESS_LIST } from 'services/livenessService/consts'
import { type ApplicationState } from 'store'
import { type Booking } from 'store/ducks/booking/types'
import * as LivenessActions from 'store/ducks/liveness/actions'
import { type LivenessState } from 'store/ducks/liveness/types'
import { generateCollapseItemList } from 'utils/antDesign'
import { getStaticPropsById } from 'utils/common'
import { censorEmail, censorPhone, toFormatedPlate } from 'utils/helpers'
import { timeAsDayjs } from 'utils/time'
import { DateFormatTypes } from 'utils/time/enums'
import DateHelper from 'utils/time/time'

function mapStateToProps(state: ApplicationState): StateProps {
  return ({
    booking: state.bookings.data,
    liveness: state.liveness
  })
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(LivenessActions, dispatch)
}

interface StateProps {
  booking: Booking
  liveness: LivenessState
}

type Props = StateProps

const LivenessView = (props: Partial<Props>) => {
  // #region Novos códigos refatorados
  const { bookingId } = useParams()
  
  const { 
    data: livenessListData,
    error: livenessListError,
    isLoading: livenessIsLoading,
    isFaceMatchButtonAvailable,
    faceMatchButtonText,
    feedbackLiveness,
    isValidating,
    generateLivenessQRCode,
    revalidate,
  } = useLivenessList(
    { bookingId }, 
    props.booking?.pickUpDatetime
  )
  
  const items = generateCollapseItemList({
    data: livenessListData.livenessList,
    label: i => new DateHelper(i.createdAt).formatDate(DateFormatTypes.dateHourMinutes),
    tagColor: i => getStaticPropsById(LIVENESS_LIST, i.status)?.color,
    tagLabel: i => getStaticPropsById(LIVENESS_LIST, i.status).label,
    component: item => (
      <>
        <p>Status: <Tag color={getStaticPropsById(LIVENESS_LIST, item.status).color}>{getStaticPropsById(LIVENESS_LIST, item.status).label}</Tag></p>
        <p>Criado em: {new DateHelper(item.createdAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        <p>Ultima Atualização em: {new DateHelper(item.updatedAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        <p>Expira em: {new DateHelper(item.expiresAt).formatDate(DateFormatTypes.dateHourMinutes)}</p>
        {(item.status === 'WAITING' || item.status === 'IN PROGRESS') && (<Button onClick={() => { modalController.openQRCodeModal(item._id) }}>Abrir QRCode</Button>)}
      </>
    )
  })
  // #endregion

  const clientFirstName = props?.booking?._embedded?.user?.personalInfo?.firstName ?? 'Não informado'
  const clientLastName = props?.booking?._embedded?.user?.personalInfo?.lastName ?? ''
  const clientFullName = `${clientFirstName} ${clientLastName}`
  const hasVehicleData = props?.booking?._embedded?.vehicle
  const hasUserData = props?.booking?._embedded?.user
  
  return (
    <div className="liveness-workspace">
      <div className="liveness-card">
        <div className="liveness-card-info">
          Cliente: {clientFullName}<br />
          Data prevista:  {timeAsDayjs(props?.booking?.pickUpDatetime).subtract(3, 'hours').format('DD/MM/YYYY HH:mm')}<br />
          Veículo: {props?.booking?._embedded?.vehicle?.model?.fullName}<br />
          Placa: {toFormatedPlate(props?.booking?._embedded?.vehicle?.registrationPlate)}<br />
          E-mail: <b>{censorEmail(props?.booking?._embedded?.user?.email)}</b><br />
          Celular: <b>{censorPhone(props?.booking?._embedded?.user?.personalInfo?.mobilePhoneNumber)}</b><br />
        </div>
        <div className="liveness-card-email">
          {hasVehicleData && hasUserData &&
            <Button
              type="primary"
              disabled={!isFaceMatchButtonAvailable}
              onClick={generateLivenessQRCode}
            >
              {faceMatchButtonText}
            </Button>}
        </div>
      </div>

      <div style={{ maxWidth: '45%' }}>
        {(!!feedbackLiveness?.message && !livenessIsLoading) && (
          <StatusCard {...feedbackLiveness}>
            {(isFaceMatchButtonAvailable && feedbackLiveness.statusType && feedbackLiveness.statusType !== StatusCardType.Info) && <img src={image} height={350} width={400}/>}
          </StatusCard>
        )}
      </div>

      <div className="liveness-history">
        <Button
          type="link"
          className="search-list"
          onClick={event => {
            event.preventDefault()
            revalidate()
          }}
          disabled={livenessIsLoading || isValidating}
          loading={livenessIsLoading || isValidating}
        >
          Atualizar Lista
        </Button>

        <FetchData
          hasData={!!livenessListData}
          error={livenessListError}
          isLoading={livenessIsLoading || isValidating}
        >
          <Collapse accordion items={items} />
        </FetchData>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LivenessView)
