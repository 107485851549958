import './App.css'

import { LoadingOutlined } from '@ant-design/icons'
import { ConfigProvider, Modal, Spin } from 'antd'
// import { setModalRef } from 'components/Modal'
import modalController from 'components/Modal'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { loadinServiceRef } from 'services/LoadingService'

import router from './router'
import store from './store'

const App = () => {
  const [modal, contextHolder] = Modal.useModal()

  useEffect(() => {
    modalController.inicialize(modal)
  }, [modal])

  return (
    <div className="App">
      <div className='website-loading' ref={loadinServiceRef}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 100 }} />} className='spin' />
      </div>

      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemActiveBg: '#00708D',
              itemBg: '#4493B1',
              itemColor: '#FFF',
              itemSelectedColor: '#FFF',
              itemSelectedBg: '#00708D'
            },
            Layout: {
              siderBg: '#4493B1'
            }
          },
          token: {
            // Disabled
            colorText: '#00708D',
            colorTextDisabled: '#97A7B1',
            colorBgContainerDisabled: '#EEEFF0',
          
            // Primary
            colorPrimary: '#00708D',
            colorPrimaryHover: '#1B809F',
            colorPrimaryActive: '#4493B1',
          
            // Default
          
            // Text
            colorBgTextHover: '#EEEFF0',
            colorBgTextActive: '#C8DBE3'
          
          }
        }}
      >
        <Provider store={store}>
          <RouterProvider router={router()} />
        </Provider>
      </ConfigProvider>

      {contextHolder}
    </div>
  )
}
export default App
