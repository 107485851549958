import { createRef } from 'react'

// eslint-disable-next-line no-undef
export const loadinServiceRef = createRef<HTMLDivElement>()

const setWebsiteLoading = (value: boolean) => {
  console.log(loadinServiceRef)

  if(!loadinServiceRef.current) return

  loadinServiceRef.current.style.display = value ? 'flex' : 'none'
}

export {
  setWebsiteLoading
}