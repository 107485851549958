import { AxiosResponse } from 'axios'

export const executePromiseApi = async <TData>(fnc: Promise<AxiosResponse<TData>>): Promise<TData> => {
  try {
    const resp = await fnc

    return resp.data
  } catch(error: any) {

    const message = error?.response?.data?.message || error.message || 'An unexpected error occurred'
    throw new Error(message)
  }
  
}