/* eslint-disable no-undef */
import modalController from 'components/Modal'
import { setWebsiteLoading } from 'services/LoadingService'
import { executePromiseApi } from 'utils/api'

import { postValidateCredentialsApi } from './api'
import { FormGTSCredentialsProps } from './types'

export const verifyCredentialsService = async (
  data: FormGTSCredentialsProps,
  successCallback: () => void
) => {
  try {
    setWebsiteLoading(true)
    
    const resp = await executePromiseApi(postValidateCredentialsApi(data))
    
    // Save cookies
    localStorage.setItem('@kinto-solutions/gts/id', data.clientId)
    localStorage.setItem('@kinto-solutions/gts/email', data.clientEmail)
    localStorage.setItem('@kinto-solutions/gts/gtsAccessToken', resp.gtsAccessToken)
    
    successCallback()
  } catch(error: any) {
    modalController.openErrorModal({ title: error.message })
  } finally {
    setWebsiteLoading(false)
  }
}