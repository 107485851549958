import { CheckCircleFilled } from '@ant-design/icons'
import { ModalFuncProps } from 'antd'
import { HookAPI } from 'antd/es/modal/useModal'
import React from 'react'
import { QRCode } from 'react-qrcode-logo'
import { texts } from 'utils/texts'

class ModalController {
  private _modalController!: HookAPI
  
  public inicialize = (modal: HookAPI) => {
    this._modalController = modal
  }

  public openInfoModal = (options: ModalFuncProps) => 
    this._modalController.info({ ...options })

  public openErrorModal = (options: ModalFuncProps) => 
    this._modalController.error({ ...options })

  public openConfirmModal = (options: ModalFuncProps) => 
    this._modalController.confirm({ ...options })

  public openQRCodeModal = (qrCode: string) => 
    this._modalController.confirm({ 
      title: texts.liveness.newProcess,
      icon: React.createElement(CheckCircleFilled, { style: { color: 'green' } }),
      content: React.createElement('div', {},
        React.createElement('p', {}, texts.liveness.qrCodeTile),
        React.createElement(
          QRCode,
          {
            value: `${process.env.REACT_APP_LIVENESS_URI}?id=${qrCode}`,
            size: 300,
            logoWidth: 50,
            logoHeight: 35,
            ecLevel: 'M',
            fgColor: '#00708d',
            logoImage: './assets/SimboloKinto.svg',
            removeQrCodeBehindLogo: true
          }
        ),
        React.createElement(
          'a', 
          { 
            href: `${process.env.REACT_APP_LIVENESS_URI}?id=${qrCode}`,
            target: '_blank'
          }, 
          `${process.env.REACT_APP_LIVENESS_URI}?id=${qrCode}`
        ),
      )
    })
}

const modalController = new ModalController()

export default modalController